import {AllActionType} from "../../types";
import {ReducerState} from "react";
import {AppThunk, RootStateType} from "../store/store";
import {checkinReport, demoAPI} from "../../api/api";
import {getRoles, setUserInfoAC, addAlertMessageCommonReducerAC} from "./commonReducer";
import {CheckinReportMemberType} from "./teamManagementReducer";
import {v1} from "uuid";
import {ActionMeta, MultiValue} from "react-select";
import {
    NewCheckinDataType
} from "../../components/pages/checkinsConstructor/checkinsConstructorBuild/checkinsConstructorBuild";
import {errorAlertItem} from "../../common";

export type CheckinOnConstructorManageType = {
    id: number,
    link: string,
    name: string,
    team: { admin: string, id: number, name: string },
    token: string,
}

export type DemoCheckinConstructorTeamType = {
    departamentId: number,
    id: number,
    isAdmin: boolean,
    isMember: boolean,
    leader: string,
    name: string,
    onboardingCompleted: boolean
}

export type DemoItemType = {
    id: number,
    name: string,
    def: boolean,
}

export type CheckinMotivatorQuestionType = {
    id: string,
    text: string,
    flag: null | string,
}

export type DemoPageStateType = {
    allDemos: Array<DemoItemType>,
    //для переключения режимов
    mode: { value: number, label: string },
    isEditModalWindowOpen: boolean,
    chosenCheckinItem: CheckinReportMemberType | null,
    //это уже для других частей
    checkins: {
        build: {
            name: string,
            teams: Array<{ value: number, label: string }>,
            chosenTeam: { value: number, label: string } | null,
            chosenFrequency: { value: number, label: string } | null,
            motivatorQuestions: Array<CheckinMotivatorQuestionType>,
            normOrMotivatorDayFlag: MultiValue<{ value: string; label: string; }>,
            motivatorDayQuestion: Array<CheckinMotivatorQuestionType>
        },
        manage: {
            checkins: Array<CheckinOnConstructorManageType>
        }
    }
}

const initialState: DemoPageStateType = {
    allDemos: [],
    mode: {value: 1, label: 'preview'},
    isEditModalWindowOpen: false,
    chosenCheckinItem: null,
    //это часть стейта для конструктора чекинов
    checkins: {
        build: {
            name: 'New checkin',
            teams: [],
            chosenTeam: null,
            chosenFrequency: null,
            motivatorQuestions: [{id: v1(), text: '', flag: null}, {id: v1(), text: '', flag: null}, {
                id: v1(),
                text: '',
                flag: null
            },
                {id: v1(), text: '', flag: null}, {id: v1(), text: '', flag: null}],
            normOrMotivatorDayFlag: [],
            motivatorDayQuestion: [{id: v1(), text: '', flag: null}]
        },
        manage: {
            checkins: []
        }
    }
}

export const demoReducer = (state = initialState, action: AllActionType): DemoPageStateType => {
    switch (action.type) {
        case 'SET-DEMOS-DEMO-REDUCER': {
            return {...state, allDemos: [...action.demos]}
        }
        case 'UPDATE-DEMO-ITEM-DEMO-REDUCER': {
            return {...state, allDemos: state.allDemos.map(d => d.id === action.id ? {...d, name: action.name} : d)}
        }
        case 'REMOVE-DEMO-ITEM-DEMO-REDUCER': {
            return {...state, allDemos: state.allDemos.filter(d => d.id !== action.id)}
        }
        case 'SET-MODE-DEMO-REDUCER': {
            return {...state, mode: action.mode}
        }
        case 'SET-EDIT-MODAL-WINDOW-OPEN-DEMO-REDUCER': {
            return {...state, isEditModalWindowOpen: action.isOpen}
        }
        case 'CHOOSE-CHECKIN-ITEM-DEMO-REDUCER': {
            return {...state, chosenCheckinItem: action.item}
        }
        //чекины
        case 'SET-CHOSEN-TEAM-CHECKINS-DEMO-REDUCER': {
            return {
                ...state,
                checkins: {
                    ...state.checkins,
                    build: {
                        ...state.checkins.build,
                        chosenTeam: action.team ? {...action.team} : state.checkins.build.chosenTeam
                    }
                }
            }
        }
        case 'SET-CHOSEN-FREQUENCY-CHECKINS-DEMO-REDUCER': {
            return {
                ...state,
                checkins: {
                    ...state.checkins,
                    build: {
                        ...state.checkins.build,
                        chosenFrequency: action.frequency ? {...action.frequency} : state.checkins.build.chosenFrequency
                    }
                }
            }
        }
        case 'SET-FLAG-FOR-MOTIVATOR-QUESTION-CHECKINS-DEMO-REDUCER': {
            return {
                ...state, checkins: {
                    ...state.checkins, build: {
                        ...state.checkins.build, motivatorQuestions: state.checkins.build.motivatorQuestions
                            .map(q => q.id === action.questionId ? {
                                ...q,
                                flag: q.flag === action.flag ? null : action.flag
                            } : q)
                    }
                }
            }
        }
        case 'SET-TEXT-FOR-MOTIVATOR-QUESTION-CHECKINS-DEMO-REDUCER': {
            return {
                ...state, checkins: {
                    ...state.checkins, build: {
                        ...state.checkins.build, motivatorQuestions: state.checkins.build.motivatorQuestions
                            .map(q => q.id === action.questionId ? {...q, text: action.text} : q)
                    }
                }
            }
        }
        case 'SET-CHOSEN-NORM-OR-MOTIVATORS-DAY-FLAG-CHECKINS-DEMO-REDUCER': {
            let a = {
                ...state,
                checkins: {
                    ...state.checkins,
                    build: {
                        ...state.checkins.build,
                        normOrMotivatorDayFlag: action.values.length ? [...action.values] : []
                    }
                }
            }
            return a
        }
        case 'SET-FLAG-FOR-MOTIVATOR-DAY-QUESTION-CHECKINS-DEMO-REDUCER': {
            return {
                ...state, checkins: {
                    ...state.checkins, build: {
                        ...state.checkins.build, motivatorDayQuestion: state.checkins.build.motivatorDayQuestion
                            .map(q => q.id === action.questionId ? {
                                ...q,
                                flag: q.flag === action.flag ? null : action.flag
                            } : q)
                    }
                }
            }
        }
        case 'SET-TEXT-FOR-MOTIVATOR-DAY-QUESTION-CHECKINS-DEMO-REDUCER': {
            return {
                ...state, checkins: {
                    ...state.checkins, build: {
                        ...state.checkins.build, motivatorDayQuestion: state.checkins.build.motivatorDayQuestion
                            .map(q => q.id === action.questionId ? {...q, text: action.text} : q)
                    }
                }
            }
        }
        case 'CLEAR-STATE-CHECKINS-DEMO-REDUCER': {
            return {
                ...state, checkins: {
                    ...state.checkins, build: {
                        ...state.checkins.build,
                        name: '',
                        chosenTeam: null,
                        chosenFrequency: null,
                        motivatorQuestions: [{id: v1(), text: '', flag: null}, {
                            id: v1(),
                            text: '',
                            flag: null
                        }, {id: v1(), text: '', flag: null},
                            {id: v1(), text: '', flag: null}, {id: v1(), text: '', flag: null}],
                        normOrMotivatorDayFlag: [],
                        motivatorDayQuestion: [{id: v1(), text: '', flag: null}]
                    }
                }
            }
        }
        case 'SET-CHECKIN-NAME-CHECKINS-DEMO-REDUCER': {
            return {...state, checkins: {...state.checkins, build: {...state.checkins.build, name: action.name}}}
        }
        case 'GET-ALL-TEAMS-CHECKINS-DEMO-REDUCER': {
            return {...state, checkins: {...state.checkins, build: {...state.checkins.build, teams: [...action.teams]}}}
        }
        case 'GET-ALL-CHECKINS-ON-CONSTRUCTOR-DEMO-REDUCER': {
            return {
                ...state,
                checkins: {...state.checkins, manage: {...state.checkins.manage, checkins: [...action.checkins]}}
            }
        }
        case 'DELETE-CHECKIN-ON-CONSTRUCTOR-DEMO-REDUCER': {
            return {
                ...state, checkins: {
                    ...state.checkins, manage: {
                        ...state.checkins.manage,
                        checkins: state.checkins.manage.checkins.filter(c => c.id !== action.checkinId)
                    }
                }
            }
        }
        default:
            return state;
    }
}

export type setDemosDemoReducerACType = ReturnType<typeof setDemosDemoReducerAC>;
export type updateDemoItemDemoReducerACType = ReturnType<typeof updateDemoItemDemoReducerAC>;
export type removeDemoItemDemoReducerACType = ReturnType<typeof removeDemoItemDemoReducerAC>;
export type setModeDemoReducerACType = ReturnType<typeof setModeDemoReducerAC>;
export type setEditModalWindowDemoReducerACType = ReturnType<typeof setEditModalWindowDemoReducerAC>;
export type setChosenCheckinItemDemoReducerACType = ReturnType<typeof setChosenCheckinItemDemoReducerAC>;


export type setChosenTeamCheckinsDemoReducerACType = ReturnType<typeof setChosenTeamCheckinsDemoReducerAC>;
export type setChosenFrequencyCheckinsDemoReducerACType = ReturnType<typeof setChosenFrequencyCheckinsDemoReducerAC>;
export type setFlagForMotivatorQuestionCheckinsDemoReducerACType = ReturnType<typeof setFlagForMotivatorQuestionCheckinsDemoReducerAC>;
export type setTextForMotivatorQuestionCheckinsDemoReducerACType = ReturnType<typeof setTextForMotivatorQuestionCheckinsDemoReducerAC>;
export type setChosenNormOrMotivatorsDayFlagDemoReducerACType = ReturnType<typeof setChosenNormOrMotivatorsDayFlagDemoReducerAC>;
export type setFlagForMotivatorDayQuestionCheckinsDemoReducerACType = ReturnType<typeof setFlagForMotivatorDayQuestionCheckinsDemoReducerAC>;
export type setTextForMotivatorDayQuestionCheckinsDemoReducerACType = ReturnType<typeof setTextForMotivatorDayQuestionCheckinsDemoReducerAC>;
export type clearStateAfterCreateCheckinInConstructorDemoReducerACType = ReturnType<typeof clearStateAfterCreateCheckinInConstructorDemoReducerAC>;
export type setCheckinNameCheckinInConstructorDemoReducerACType = ReturnType<typeof setCheckinNameCheckinInConstructorDemoReducerAC>;
export type getTeamsCheckinInConstructorDemoReducerACType = ReturnType<typeof getTeamsCheckinInConstructorDemoReducerAC>;
export type getCheckinsCheckinInConstructorDemoReducerACType = ReturnType<typeof getCheckinsCheckinInConstructorDemoReducerAC>;
export type deleteCheckinInConstructorDemoReducerACType = ReturnType<typeof deleteCheckinInConstructorDemoReducerAC>;
export const setDemosDemoReducerAC = (demos: Array<DemoItemType>) => ({type: 'SET-DEMOS-DEMO-REDUCER', demos} as const);
export const updateDemoItemDemoReducerAC = (id: number, name: string) => ({
    type: 'UPDATE-DEMO-ITEM-DEMO-REDUCER',
    id,
    name
} as const);
export const removeDemoItemDemoReducerAC = (id: number) => ({type: 'REMOVE-DEMO-ITEM-DEMO-REDUCER', id} as const);
export const setModeDemoReducerAC = (mode: { value: number, label: string }) => ({
    type: 'SET-MODE-DEMO-REDUCER',
    mode
} as const);
export const setEditModalWindowDemoReducerAC = (isOpen: boolean) => ({
    type: 'SET-EDIT-MODAL-WINDOW-OPEN-DEMO-REDUCER',
    isOpen
} as const);

export const setChosenCheckinItemDemoReducerAC = (item: any) => ({
    type: 'CHOOSE-CHECKIN-ITEM-DEMO-REDUCER',
    item
} as const);

//для чекинов
export const setChosenTeamCheckinsDemoReducerAC = (team: {
    value: number,
    label: string
} | null) => ({type: 'SET-CHOSEN-TEAM-CHECKINS-DEMO-REDUCER', team} as const);
export const setChosenFrequencyCheckinsDemoReducerAC = (frequency: {
    value: number,
    label: string
} | null) => ({type: 'SET-CHOSEN-FREQUENCY-CHECKINS-DEMO-REDUCER', frequency} as const);

export const setFlagForMotivatorQuestionCheckinsDemoReducerAC = (flag: string, questionId: string) => ({
    type: 'SET-FLAG-FOR-MOTIVATOR-QUESTION-CHECKINS-DEMO-REDUCER',
    flag,
    questionId
} as const);
export const setTextForMotivatorQuestionCheckinsDemoReducerAC = (text: string, questionId: string) => ({
    type: 'SET-TEXT-FOR-MOTIVATOR-QUESTION-CHECKINS-DEMO-REDUCER',
    text,
    questionId
} as const);
export const setChosenNormOrMotivatorsDayFlagDemoReducerAC = (values: MultiValue<{
    value: string;
    label: string;
}>) => ({type: 'SET-CHOSEN-NORM-OR-MOTIVATORS-DAY-FLAG-CHECKINS-DEMO-REDUCER', values} as const);
export const setFlagForMotivatorDayQuestionCheckinsDemoReducerAC = (flag: string, questionId: string) => ({
    type: 'SET-FLAG-FOR-MOTIVATOR-DAY-QUESTION-CHECKINS-DEMO-REDUCER',
    flag,
    questionId
} as const);
export const setTextForMotivatorDayQuestionCheckinsDemoReducerAC = (text: string, questionId: string) => ({
    type: 'SET-TEXT-FOR-MOTIVATOR-DAY-QUESTION-CHECKINS-DEMO-REDUCER',
    text,
    questionId
} as const);

export const clearStateAfterCreateCheckinInConstructorDemoReducerAC = () => ({type: 'CLEAR-STATE-CHECKINS-DEMO-REDUCER'} as const);

export const setCheckinNameCheckinInConstructorDemoReducerAC = (name: string) => ({
    type: 'SET-CHECKIN-NAME-CHECKINS-DEMO-REDUCER',
    name
} as const);

export const getTeamsCheckinInConstructorDemoReducerAC = (teams: Array<any>) => ({
    type: 'GET-ALL-TEAMS-CHECKINS-DEMO-REDUCER',
    teams
} as const)

export const getCheckinsCheckinInConstructorDemoReducerAC = (checkins: Array<CheckinOnConstructorManageType>) => ({
    type: 'GET-ALL-CHECKINS-ON-CONSTRUCTOR-DEMO-REDUCER',
    checkins
} as const)

export const deleteCheckinInConstructorDemoReducerAC = (checkinId: number) => ({
    type: 'DELETE-CHECKIN-ON-CONSTRUCTOR-DEMO-REDUCER',
    checkinId
} as const);
/////////
export const getDemosTC = (setLoading: (isLoading: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    demoAPI.getDemos().then(res => {
        dispatch(setDemosDemoReducerAC(res.data.demos))
        setLoading(false)
    }).catch((err) => {
        setLoading(false)
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const makeDemoCopyTC = (id: number, setLoading: (isLoading: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    setLoading(true);
    demoAPI.makeDemoCopy(id).then(res => {
        let demos = getState().demo.allDemos;
        let chosenDemo = demos.find(i => i.id === id);
        let chosenDemoIndex = chosenDemo && demos.indexOf(chosenDemo);
        (chosenDemoIndex || chosenDemoIndex === 0) && demos.splice(chosenDemoIndex + 1, 0, res.data);
        dispatch(setDemosDemoReducerAC(demos));
        setLoading(false)
    }).catch((err) => {
        setLoading(false)
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const updateDemoTitleTC = (id: number, name: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    demoAPI.updateDemo(id, name).then(res => {
        dispatch(updateDemoItemDemoReducerAC(id, name));
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const removeDemoTC = (id: number, setLoading: (isLoading: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    setLoading(true);
    demoAPI.removeDemo(id).then(res => {
        dispatch(removeDemoItemDemoReducerAC(id));
        setLoading(false)
    }).catch((err) => {
        setLoading(false)
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const getCertainDemoInfoTC = (id: number,
                                     navigate: (url: string) => void,
                                     pathName: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    demoAPI.getCertainDemoInfo(id).then(res => {

        const userInfo = {
            name: `${res.data.user.firstName} ${res.data.user.lastName}`,
            email: res.data.user.email,
            shortName: `${res.data.user.firstName[0]}${res.data.user.lastName[0]}`
        }

        localStorage.setItem('token', res.data.token);

        let oldEmailFromLS = localStorage.getItem('email');
        if (oldEmailFromLS) {
            localStorage.removeItem('email');
            localStorage.setItem('email', userInfo.email);
        } else {
            localStorage.setItem('email', userInfo.email);
        }

        dispatch(setUserInfoAC(userInfo));
        //navigate на дашборд добавила в логику getRoles()
        dispatch(getRoles(navigate, pathName));
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

//для чекинов

///get all teams on checkins constructor (its own getHeaders with moderator role!!!)
export const getAllTeamsDemoCheckinsConstructorTC = (setError: (notification: string) => void,
                                                     setSuccess: (notification: string) => void,
                                                     setLoading: (isLoading: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    // setLoading(true);
    demoAPI.getALlTeamsCheckinsConstructor().then(res => {
        let teams = res.data.teams.map((t: DemoCheckinConstructorTeamType) => ({value: t.id, label: t.name}))
        dispatch(getTeamsCheckinInConstructorDemoReducerAC(teams))
        //   setLoading(false)
    }).catch((err) => {
        //   setLoading(false)
        setError('wrong getting teams on checkin constructor')
    })
}

////////////
export const createCheckinFromConstructorDemoTC = (teamId: number,
                                                   checkin: NewCheckinDataType,
                                                   setError: (notification: string) => void,
                                                   setSuccess: (notification: string) => void,
                                                   setLoading: (isLoading: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    setLoading(true);
    demoAPI.createCheckinUsingConstructor(teamId, checkin).then(res => {
        dispatch(clearStateAfterCreateCheckinInConstructorDemoReducerAC());
        setLoading(false);
        setSuccess('Сheckin has been successfully created!');
    }).catch((err) => {
        setLoading(false)
        setError('Something wrong creating checkin!');
    })
}

export const getCheckinsConstructorManageDemoTC = (setError: (notification: string) => void,
                                                   setSuccess: (notification: string) => void,
                                                   setLoading: (isLoading: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    setLoading(true);
    demoAPI.getCheckins().then(res => {
        let checkins: Array<CheckinOnConstructorManageType> = res.data;
        dispatch(getCheckinsCheckinInConstructorDemoReducerAC(checkins))
        setLoading(false);
    }).catch((err) => {
        setLoading(false)
        if (err.response.data.message !== "You don't have any check-ins yet.") {
            setError(err.response.data.message);
        }
    })
}

export const deleteCheckinConstructorManageDemoTC = (checkinId: number,
                                                     setError: (notification: string) => void,
                                                     setSuccess: (notification: string) => void,
                                                     setLoading: (isLoading: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    setLoading(true);
    demoAPI.deleteCheckinOnConstructor(checkinId).then(res => {
        dispatch(deleteCheckinInConstructorDemoReducerAC(checkinId))
        setLoading(false);
    }).catch((err) => {
        setLoading(false)
        setError(err.response.data.message);
    })
}

export const editCheckinInConstructorDemoTC = (checkin: NewCheckinDataType,
                                               checkinId: number,
                                               closeModalWindow: () => void,
                                               setActiveTab: (tab: 'build' | 'manage') => void,
                                               setError: (notification: string) => void,
                                               setSuccess: (notification: string) => void,
                                               setLoading: (isLoading: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    setLoading(true);
    demoAPI.editCheckinInConstructor(checkinId, checkin).then(res => {
        setLoading(false);
        demoAPI.getCheckins().then(res => {
            // setLoading(false);
            let checkins: Array<CheckinOnConstructorManageType> = res.data;
            dispatch(getCheckinsCheckinInConstructorDemoReducerAC(checkins))
            setSuccess('Сheckin has been successfully edited!')
        }).catch((err) => {
            setLoading(false)
            if (err.response.data.message !== "You don't have any check-ins yet.") {
                setError(err.response.data.message);
            }
        })
        // setLoading(false);
        // closeModalWindow();
        // setActiveTab('build');
        setSuccess('Сheckin has been successfully edited!')
    }).catch((err) => {
        setLoading(false)
        setError(err.response.data.message);
    })
}
import {AllActionType} from "../../types";
import {Dispatch} from "redux";
import {dashboardAPI, stripeAPI, teamManagementAPI, userAPI} from "../../api/api";
import {addAlertMessageCommonReducerAC, setLoadingCommonReducerAC, setTourCompletedAC, setUserInfoAC} from "./commonReducer";
import {sendInfo} from "../../components/pages/dashboard/analysis/analysis";
import {AppThunk, RootStateType} from "../store/store";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {Buffer} from 'buffer';
import {errorAlertItem} from "../../common";

export type ChosenTeamType = any;

export type NormType = {
    label: string,
    text: string,
    toolText: Array<string>,
    value: number
}

export type AnalitycsInfoType = {
    motivationAvg: number,
    performanceAvg: number,
    sentimentAvg: number,
    motivationLine: Array<{ name: string, data: Array<number | string> | undefined }>,
    performanceLine: Array<{ name: string, data: Array<number | string> | undefined }>,
    sentimentLine: Array<{ name: string, data: Array<number | string> | undefined }>,
    motivationLineCategories: Array<string | null>,
    performanceLineCategories: Array<string | null>,
    sentimentLineCategories: Array<string | null>,
}

export type CompanyOverviewInfo = {
    performanceForDonut: number,
    performanceForLine: Array<number> | any,
    motivationForDonut: number,
    motivationForLine: Array<number> | any,
    sentimentForDonut: number,
    sentimentForLine: Array<number> | any,
    norms: Array<NormType>,
    selectedNorm: NormType | {}
    //добавила для новых графиков
    graphDataENPS: Array<{ name: string, data: Array<number> | [], label: Array<number> | [] }> | [],
    graphDataOnboarding: Array<{ name: string, data: Array<number> | [], label: Array<number> | [] }> | [],
    seriesRadialForENPS: Array<number> | [],
    seriesRadialForOnboarding: Array<number> | []
};

export type TeamType = {
    departamentId: number,
    id: number,
    leaderemail: string,
    leadername: string,
    motivation: number,
    name: string,
    productivity: number,
    sentiment: number

}

export type AvailableTeamType = {
    id: number,
    isAdmin: boolean,
    isMember: boolean,
    leader: string,
    name: string,
    onboardingCompleted: boolean
}

export type ChosenTeamInfoType = {
    teamId: number,
    teamName: string,
    members: Array<{ value: string, label: string }> | [],
    leader: { value: number, label: string },
    motivationAvg: number,
    motivationLineGraph: Array<{ date: string, record: number }>,
    sentimentAvg: number,
    sentimentLineGraph: Array<{ date: string, record: number }>,
    sentimentAvgBad: number,
    sentimentAvgGood: number,
    sentimentAvgMeh: number,
    sentimentBarGraph: Array<{
        date: string,
        good: string,
        meh: string,
        bad: string,
        badItems: { [key: string]: string },
        goodItems: { [key: string]: string },
        mehItems: { [key: string]: string }
    }>,
    performanceAvg: number | null,
    performanceLineGraph: Array<any>,
    productivity_sentiment_category: Array<string>,
    norms: Array<{ label: string, text: string, toolText: Array<string>, value: number }>
}

export type ModalWindowForStripeType = {
    companyId: number,
    freePriceId: string,
    publishableKey: string,
    subscribed: boolean,
    unlimitedYearlyPriceId: string
}

export type PaymentInfoForStripeType = {
    alertData: boolean,
    hasTeams: boolean,
    maxPlanUserCount: number,
    member: boolean,
    subscribed: boolean,
    userCount: number
}

export type LinePointType = {
    time: string,
    order: number,
    value: number,
};

export type BarPointType = {
    time: string,
    order: number,
    good: number,
    meh: number,
    bad: number,
};

export type HorizontalBarPointType = {
    companyId: number,
    time: string,
    detractors: number,
    neutral: number,
    promoters: number,
}

export type DemoGraphsDataType = {
    motivationGraph: Array<LinePointType>,
    sentimentGraph: Array<BarPointType>,
    psGraph: {
        performance: Array<LinePointType>,
        sentiment: Array<LinePointType>,
    },
    enpsGraph: Array<HorizontalBarPointType>,
    onboardingGraph: Array<HorizontalBarPointType>,
    companyIdForDashboardGraph: number,
};

export type DemoGraphType = 'motivation' | 'performance_sentiment' | 'sentiment_types' | 'onboarding' | 'enps';

export type PdfReportsModalWindowUserType = {
    email: string,
    firstName: string,
    id: number,
    lastName: string,
    role: string
}

export type PdfReportsModalWindowTeamType = {
    departamentId: number,
    departmenAdmin: number | null,
    department_name: string,
    id: number,
    name: string,
    teamAdmin: number
};

export type PdfReportsModalWindowInfoType = {
    teams: Array<PdfReportsModalWindowTeamType> | null,
    users: Array<PdfReportsModalWindowUserType> | null,
}

export type DashboardType = {
    activeTabCompany: string,
    activeTabTeam: string,
    selectedTimeFrame: { value: string, label: string },
    //это выбранные департаменты из седекта на teamPage
    selectedDepartments: Array<{ value: string, label: string }> | [],
    //это выбранный департамент для дропдауна на companyOverview для isAdminDepartment
    chosenDepartment: { value: string, label: string } | null,
    //это когда в фильтре модератор на странице анализа выбироает команды
    selectedTeams: Array<{ value: string, label: string }> | [],
    selectedMembers: Array<any> | null,
    allDepartments: Array<{ value: string, label: string }> | [],
    modalWindowForStripe: ModalWindowForStripeType,
    paymentInfoForStripe: PaymentInfoForStripeType,
    company: CompanyOverviewInfo,
    //когда департаменты выбраны, а если нет, то это allTems
    filteredTeams: Array<TeamType> | [],
    //это все команды, которые доступны для просмотра чуваку в зависимости от текущего статуса
    availableTeams: Array<AvailableTeamType>,
    loaderForDownloadReportButton: boolean,
    loaderForShareReportButton: boolean,
    teams: {
        //когда департаменты выбраны, а если нет, то это allTems
        //  filteredTeams: Array<any> | null,
        chosenTeam: ChosenTeamType,
    },
    analysis: {
        info: AnalitycsInfoType | null,
        indexForColor: number,
        colors: Array<string>,
        colorData: any
    },
    certainTeam: {
        //когда выбрали конкретную команду и перешли на страницу certainTeam
        chosenTeamId: number | null,
        chosenTeamInfo: ChosenTeamInfoType | null,
        //выбранный из селекта конкретный участник команды
        selectedTeamMember: { value: string, label: string } | null,
        overview: {
            //если не выбраны, то это все участники
            filteredMembers: Array<any> | null,
            motivationAverage: number,
            motivationOvertime: Array<number>,
            sentimentAverage: Array<number>,
            sentimentOvertime: Array<number>,
            teamValues: Array<any>,
            teamCharacteristics: Array<string>
        },
        performance: {
            //если не выбраны, то это все участники
            filteredMembers: Array<any> | null,
            average: Array<number>,
            averageOvertime: Array<any>
        },
        demoGraphs: DemoGraphsDataType
    },
    pdfReports: {
        modalWindow: PdfReportsModalWindowInfoType,
    }
}

let initialState: DashboardType = {
    activeTabCompany: 'Overview of Company',
    activeTabTeam: 'Overview',
    selectedTimeFrame: {value: '1m', label: 'Last 30 days'},
    selectedDepartments: [],
    chosenDepartment: null,
    selectedTeams: [],
    selectedMembers: null,
    allDepartments: [],
    modalWindowForStripe: {
        companyId: 0,
        freePriceId: '',
        publishableKey: '',
        subscribed: false,
        unlimitedYearlyPriceId: ''
    },
    paymentInfoForStripe: {
        alertData: false,
        hasTeams: false,
        maxPlanUserCount: 0,
        member: false,
        subscribed: true,
        userCount: 0
    },
    company: {
        performanceForDonut: 0,
        // performanceForLine: [0],
        performanceForLine: null,
        motivationForDonut: 0,
        // motivationForLine: [0],
        motivationForLine: null,
        sentimentForDonut: 0,
        //sentimentForLine: [0],
        sentimentForLine: null,
        graphDataENPS: [],
        graphDataOnboarding: [],
        seriesRadialForENPS: [],
        seriesRadialForOnboarding: [],
        norms: [],
        selectedNorm: {}
    },
    filteredTeams: [],
    availableTeams: [],
    loaderForDownloadReportButton: false,
    loaderForShareReportButton: false,
    teams: {
        //когда департаменты выбраны, а если нет, то это allTems
        chosenTeam: {},
    },
    analysis: {
        info: null,
        indexForColor: 0,
        colors: [],
        colorData: new Map([["Company Average", "#0d0d0e"]]),
    },
    certainTeam: {
        chosenTeamId: null,
        chosenTeamInfo: null,
        selectedTeamMember: null,
        overview: {
            //если не выбраны, то это все участники
            filteredMembers: null,
            motivationAverage: 50,
            motivationOvertime: [20, 20, 20, 20],
            sentimentAverage: [20, 20, 20],
            sentimentOvertime: [20, 20, 20, 20],
            teamValues: [],
            teamCharacteristics: []
        },
        performance: {
            //если не выбраны, то это все участники
            filteredMembers: null,
            average: [20, 20],
            averageOvertime: [20, 20, 20, 20]
        },
        demoGraphs: {
            motivationGraph: [{time: '1w', order: 5, value: 27}, {time: '1w', order: 1, value: 50}, {
                time: '1w',
                order: 7,
                value: 39
            },
                {time: '1w', order: 2, value: 87}, {time: '1w', order: 4, value: 46}, {time: '1w', order: 3, value: 11},
                {time: '1w', order: 6, value: 64}],
            sentimentGraph: [],
            psGraph: {
                performance: [],
                sentiment: []
            },
            enpsGraph: [],
            onboardingGraph: [],
            companyIdForDashboardGraph: 0,
        }
    },
    pdfReports: {
        modalWindow: {
            teams: null,
            users: null,
        },
    }
}


export const dashboardReducer = (state = initialState, action: AllActionType): DashboardType => {
    switch (action.type) {
        // case 'SET-TOUR-COMPLETED-DASHBOARD': {
        //     return {...state, tourCompleted: action.completed}
        // }
        case 'SET-LOADER-FOR-DOWNLOAD-REPORT-BUTTON': {
            return {...state, loaderForDownloadReportButton: action.loader}
        }
        case 'SET-LOADER-FOR-SHARE-REPORT-BUTTON': {
            return {...state, loaderForShareReportButton: action.loader}
        }
        case 'SET-ACTIVE-TAB-COMPANY': {
            return {...state, activeTabCompany: action.tab}
        }
        case 'SET-ACTIVE-TAB-TEAM': {
            return {...state, activeTabTeam: action.tab}
        }
        case 'SET-CHOSEN-DEPARTMENT-COMPANY': {
            return {...state, chosenDepartment: {...action.department}}
        }
        case "SET-SELECTED-DEPARTMENTS": {
            return {...state, selectedDepartments: [...action.departments]}
        }
        case "SET-SELECTED-TEAMS": {
            return {...state, selectedTeams: action.teams}
        }
        case "SET-TIME-FRAME": {
            return {...state, selectedTimeFrame: action.timeFrame}
        }
        case "SET-COMPANY-OVERVIEW-INFO": {
            return {
                ...state,
                company: {...action.companyOverviewInfo}
            }
        }
        case "SET-ALL-DEPARTMENTS": {
            return {...state, allDepartments: action.departments}
        }
        case "SET-FILTERED-TEAMS": {
            return {...state, filteredTeams: action.teams}
        }
        case "SET-CHOSEN-TEAM-ID": {
            return {...state, certainTeam: {...state.certainTeam, chosenTeamId: action.id}}
        }
        case "SET-AVAILABLE-TEAMS": {
            return {...state, availableTeams: action.teams}
        }
        case "SET-CHOSEN-TEAM-INFO": {
            return {...state, certainTeam: {...state.certainTeam, chosenTeamInfo: action.info ? {...action.info} : action.info}};
        }
        case "SET-SELECTED-TEAM-MEMBER": {
            return {...state, certainTeam: {...state.certainTeam, selectedTeamMember: action.member}}
        }
        case "SET-ANALYTICS-INFO": {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    info: {...action.info},
                    colorData: action.colorDataNew,
                    colors: action.arrayOfColorsForGraphs,
                    indexForColor: action.indexNew
                }
            }
        }
        case 'DASHBOARD-COMPANY-SET-INFO-FOR-MODAL-WINDOW-STRIPE': {
            return {...state, modalWindowForStripe: {...state.modalWindowForStripe, ...action.info}}
        }
        case 'DASHBOARD-COMPANY-SET-PAYMENT-INFO-FOR-STRIPE': {
            return {...state, paymentInfoForStripe: {...state.paymentInfoForStripe, ...action.info}}
        }
        case 'DASHBOARD-SET-INFO-FOR-PDF-MODAL-WINDOW': {
            return {...state, pdfReports: {...state.pdfReports, modalWindow: {...action.pdfModalWindowInfo}}}
        }
        //demos
        case 'DASHBOARD-CERTAIN-TEAM-DEMOS-ADD-NEW-POINT-MOTIVATION-LINE': {
            return {
                ...state, certainTeam: {
                    ...state.certainTeam, demoGraphs: {
                        ...state.certainTeam.demoGraphs,
                        motivationGraph: [...state.certainTeam.demoGraphs.motivationGraph, action.newPoint]
                    }
                }
            }
        }
        case 'DASHBOARD-CERTAIN-TEAM-DEMOS-DELETE-POINT-MOTIVATION-LINE': {
            return {
                ...state, certainTeam: {
                    ...state.certainTeam, demoGraphs: {
                        ...state.certainTeam.demoGraphs,
                        motivationGraph: state.certainTeam.demoGraphs.motivationGraph.filter((i: any) => i.order !== action.pointOrder)
                    }
                }
            }
        }
        case 'DASHBOARD-CERTAIN-TEAM-DEMOS-SET-GRAPH-DATA': {
            return action.graphType === 'motivation' ? {
                    ...state, certainTeam: {
                        ...state.certainTeam,
                        demoGraphs: {...state.certainTeam.demoGraphs, motivationGraph: [...action.data]}
                    }
                }
                : action.graphType === 'sentiment_types' ? {
                        ...state, certainTeam: {
                            ...state.certainTeam,
                            demoGraphs: {...state.certainTeam.demoGraphs, sentimentGraph: [...action.data]}
                        }
                    }
                    : action.graphType === 'performance_sentiment' ? {
                            ...state, certainTeam: {
                                ...state.certainTeam,
                                demoGraphs: {...state.certainTeam.demoGraphs, psGraph: {...action.data}}
                            }
                        }
                        : action.graphType === 'onboarding' ? {
                                ...state, certainTeam: {
                                    ...state.certainTeam,
                                    demoGraphs: {...state.certainTeam.demoGraphs, onboardingGraph: [...action.data]}
                                }
                            }
                            : action.graphType === 'enps' ? {
                                    ...state, certainTeam: {
                                        ...state.certainTeam,
                                        demoGraphs: {...state.certainTeam.demoGraphs, enpsGraph: [...action.data]}
                                    }
                                }
                                : {...state};
        }
        case 'DASHBOARD-CERTAIN-TEAM-DEMOS-DELETE-POINT-PERFORMANCE-AND-SENTIMENT': {
            return {
                ...state, certainTeam: {
                    ...state.certainTeam, demoGraphs: {
                        ...state.certainTeam.demoGraphs,
                        psGraph: {
                            ...state.certainTeam.demoGraphs.psGraph,
                            [action.graph]: state.certainTeam.demoGraphs.psGraph[action.graph].filter(i => i.order !== action.pointOrder)
                        }
                    }
                }
            }
        }
        case 'DASHBOARD-CERTAIN-TEAM-DEMOS-ADD-POINT-PERFORMANCE-AND-SENTIMENT': {
            return {
                ...state, certainTeam: {
                    ...state.certainTeam, demoGraphs: {
                        ...state.certainTeam.demoGraphs,
                        psGraph: {
                            ...state.certainTeam.demoGraphs.psGraph,
                            [action.graph]: [...state.certainTeam.demoGraphs.psGraph[action.graph], action.newPoint]
                        }
                    }
                }
            }
        }
        case 'DASHBOARD-CERTAIN-TEAM-DEMOS-ADD-POINT-SENTIMENT': {
            return {
                ...state, certainTeam: {
                    ...state.certainTeam, demoGraphs: {
                        ...state.certainTeam.demoGraphs,
                        sentimentGraph: [...state.certainTeam.demoGraphs.sentimentGraph, action.newPoint]
                    }
                }
            }
        }
        case 'DASHBOARD-CERTAIN-TEAM-DEMOS-DELETE-POINT-SENTIMENT': {
            return {
                ...state, certainTeam: {
                    ...state.certainTeam, demoGraphs: {
                        ...state.certainTeam.demoGraphs,
                        sentimentGraph: state.certainTeam.demoGraphs.sentimentGraph.filter((i: any) => i.order !== action.pointOrder)
                    }
                }
            }
        }
        case 'DASHBOARD-SET-COMPANY-ID-DEMOS-UPDATE-HORIZONTAL-BAR-GRAPH-DATA': {
            return {
                ...state,
                certainTeam: {
                    ...state.certainTeam,
                    demoGraphs: {...state.certainTeam.demoGraphs, companyIdForDashboardGraph: action.id}
                }
            }
        }
        default:
            return state;
    }
}
//export type setTourCompletedDashboardACType = ReturnType<typeof setTourCompletedDashboardAC>;
export type setActiveTabCompanyACType = ReturnType<typeof setActiveTabCompanyAC>;
export type setActiveTabTeamACType = ReturnType<typeof setActiveTabTeamAC>;
export type setChosenDepartmentDashboardACType = ReturnType<typeof setChosenDepartmentDashboardAC>;
export type setSelectedDepartmentsACType = ReturnType<typeof setSelectedDepartmentsAC>;
export type setSelectedTeamsACType = ReturnType<typeof setSelectedTeamsAC>;
export type setTimeFrameACType = ReturnType<typeof setTimeFrameAC>;
export type setCompanyOverviewInfoACType = ReturnType<typeof setCompanyOverviewInfoAC>;
export type setAllDepartmentsACType = ReturnType<typeof setAllDepartmentsAC>;
export type setFilteredTeamsACType = ReturnType<typeof setFilteredTeamsAC>;
export type setChosenTeamIdACType = ReturnType<typeof setChosenTeamIdAC>;
export type setAvailableTeamsACType = ReturnType<typeof setAvailableTeamsAC>;
export type setChosenTeamInfoACType = ReturnType<typeof setChosenTeamInfoAC>;
export type setSelectedTeamMemberACType = ReturnType<typeof setSelectedTeamMemberAC>;
export type setAnalyticsInfoACType = ReturnType<typeof setAnalyticsInfoAC>;

export type setInfoForModalWindowStripeType = ReturnType<typeof setInfoForModalWindowStripeAC>;
export type setPaymentInfoForStripeType = ReturnType<typeof setPaymentInfoForStripeAC>;

//для демок на графиках меняем инфу
export type setDemoGraphDataACType = ReturnType<typeof setDemoGraphDataAC>;
export type addNewPointMotivationLineACType = ReturnType<typeof addNewPointMotivationLineAC>;
export type deletePointMotivationLineACType = ReturnType<typeof deletePointMotivationLineAC>;
export type deletePointPerformanceAndSentimentACType = ReturnType<typeof deletePointPerformanceAndSentimentAC>;
export type addNewPointPerformanceAndSentimentACType = ReturnType<typeof addNewPointPerformanceAndSentimentAC>;
export type addNewBarPointSentimentACType = ReturnType<typeof addNewBarPointSentimentAC>;
export type deleteBarPointSentimentACType = ReturnType<typeof deleteBarPointSentimentAC>;
export type updateHorizontalBarGraphDataACType = ReturnType<typeof updateHorizontalBarGraphDataAC>;
export type setCompanyIdHorizontalBarGraphDataACType = ReturnType<typeof setCompanyIdHorizontalBarGraphDataAC>;


export type setPDFReportsModalWindowInfoACType = ReturnType<typeof setPDFReportsModalWindowInfoAC>;

export type setLoaderForDownloadReportButtonACType = ReturnType<typeof setLoaderForDownloadReportButtonAC>;
export type setLoaderForShareReportButtonACType = ReturnType<typeof setLoaderForShareReportButtonAC>;

//export const setTourCompletedDashboardAC = (completed: boolean) => ({type: 'SET-TOUR-COMPLETED-DASHBOARD', completed}as const);
export const setActiveTabCompanyAC = (tab: string) => ({type: 'SET-ACTIVE-TAB-COMPANY', tab} as const);
export const setActiveTabTeamAC = (tab: string) => ({type: 'SET-ACTIVE-TAB-TEAM', tab} as const);

export const setLoaderForDownloadReportButtonAC = (loader: boolean) => ({
    type: 'SET-LOADER-FOR-DOWNLOAD-REPORT-BUTTON',
    loader
} as const);
export const setLoaderForShareReportButtonAC = (loader: boolean) => ({
    type: 'SET-LOADER-FOR-SHARE-REPORT-BUTTON',
    loader
} as const);

export const setChosenDepartmentDashboardAC = (department: {
    value: string,
    label: string
}) => ({type: 'SET-CHOSEN-DEPARTMENT-COMPANY', department} as const);

export const setSelectedDepartmentsAC = (departments: Array<{ value: string, label: string }> | []) => ({
    type: 'SET-SELECTED-DEPARTMENTS',
    departments
} as const);
//это когда в фильтре модератор на странице анализа выбироает команды
export const setSelectedTeamsAC = (teams: Array<{ value: string, label: string }> | []) => ({
    type: 'SET-SELECTED-TEAMS',
    teams
} as const);
export const setTimeFrameAC = (timeFrame: { value: string, label: string }) => ({
    type: 'SET-TIME-FRAME',
    timeFrame
} as const);
export const setCompanyOverviewInfoAC = (companyOverviewInfo: any) => ({
    type: 'SET-COMPANY-OVERVIEW-INFO',
    companyOverviewInfo
} as const)
export const setAllDepartmentsAC = (departments: Array<{ value: string, label: string }>) => ({
    type: 'SET-ALL-DEPARTMENTS',
    departments
} as const);
export const setFilteredTeamsAC = (teams: Array<TeamType>) => ({type: 'SET-FILTERED-TEAMS', teams} as const);
export const setChosenTeamIdAC = (id: number) => ({type: 'SET-CHOSEN-TEAM-ID', id} as const);
export const setAvailableTeamsAC = (teams: Array<AvailableTeamType>) => ({type: 'SET-AVAILABLE-TEAMS', teams} as const);
export const setChosenTeamInfoAC = (info: ChosenTeamInfoType) => ({type: 'SET-CHOSEN-TEAM-INFO', info} as const);

export const setSelectedTeamMemberAC = (member: { value: string, label: string } | null) => ({
    type: 'SET-SELECTED-TEAM-MEMBER',
    member
} as const);

export const setAnalyticsInfoAC = (info: AnalitycsInfoType, colorDataNew: any, indexNew: number,
                                   arrayOfColorsForGraphs: Array<string>) => ({
    type: 'SET-ANALYTICS-INFO',
    info,
    colorDataNew,
    indexNew,
    arrayOfColorsForGraphs
} as const);

//индекс для цветов линий в аналитике
export const setIndexForColorAc = (index: number) => ({type: 'SET-INDEX-FOR-COLOR', index} as const);

//сетаем инфу для модалки страйпа
export const setInfoForModalWindowStripeAC = (info: ModalWindowForStripeType) => ({
    type: 'DASHBOARD-COMPANY-SET-INFO-FOR-MODAL-WINDOW-STRIPE',
    info
} as const);

export const setPaymentInfoForStripeAC = (info: PaymentInfoForStripeType) => ({
    type: 'DASHBOARD-COMPANY-SET-PAYMENT-INFO-FOR-STRIPE',
    info
} as const);

//сетаем инфу для модалки pdf отчеты
export const setPDFReportsModalWindowInfoAC = (pdfModalWindowInfo: PdfReportsModalWindowInfoType) => ({
    type: 'DASHBOARD-SET-INFO-FOR-PDF-MODAL-WINDOW',
    pdfModalWindowInfo
} as const);


//для демок на графиках меняем инфу
export const setDemoGraphDataAC = (data: any, graphType: DemoGraphType) => ({
    type: 'DASHBOARD-CERTAIN-TEAM-DEMOS-SET-GRAPH-DATA',
    data,
    graphType
} as const);
export const addNewPointMotivationLineAC = (newPoint: any) => ({
    type: 'DASHBOARD-CERTAIN-TEAM-DEMOS-ADD-NEW-POINT-MOTIVATION-LINE',
    newPoint
} as const);
export const deletePointMotivationLineAC = (pointOrder: number) => ({
    type: 'DASHBOARD-CERTAIN-TEAM-DEMOS-DELETE-POINT-MOTIVATION-LINE',
    pointOrder
} as const);
export const deletePointPerformanceAndSentimentAC = (pointOrder: number, graph: 'sentiment' | 'performance') =>
    ({type: 'DASHBOARD-CERTAIN-TEAM-DEMOS-DELETE-POINT-PERFORMANCE-AND-SENTIMENT', pointOrder, graph} as const);
export const addNewPointPerformanceAndSentimentAC = (newPoint: any, graph: 'sentiment' | 'performance') =>
    ({type: 'DASHBOARD-CERTAIN-TEAM-DEMOS-ADD-POINT-PERFORMANCE-AND-SENTIMENT', newPoint, graph} as const);
export const addNewBarPointSentimentAC = (newPoint: any) =>
    ({type: 'DASHBOARD-CERTAIN-TEAM-DEMOS-ADD-POINT-SENTIMENT', newPoint} as const);
export const deleteBarPointSentimentAC = (pointOrder: number) =>
    ({type: 'DASHBOARD-CERTAIN-TEAM-DEMOS-DELETE-POINT-SENTIMENT', pointOrder} as const);
//update enps/onboarding graph data
export const updateHorizontalBarGraphDataAC = (graphType: DemoGraphType, time: string, teamId: number, valueName: string, value: number) =>
    ({
        type: 'DASHBOARD-COMPANY-DEMOS-UPDATE-HORIZONTAL-BAR-GRAPH-DATA',
        graphType,
        time,
        teamId,
        valueName,
        value
    } as const);
export const setCompanyIdHorizontalBarGraphDataAC = (id: number) => ({
    type: 'DASHBOARD-SET-COMPANY-ID-DEMOS-UPDATE-HORIZONTAL-BAR-GRAPH-DATA',
    id
} as const);


export const getCompanyOverviewInfo = (selectedTimeFrame: {
    value: string,
    label: string
}, departmentId?: string) => async (dispatch: Dispatch<AllActionType>, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));
    dashboardAPI.companyOverview(selectedTimeFrame.value, departmentId).then(res => {
        let norms = res.data.norms.map((n: any) => ({...n}))

        const graphDataENPS = [
            {
                name: 'Detractors',
                data: res.data.enps.detractors.value !== 0 ? [res.data.enps.detractors.value] : [],
                label: res.data.enps.detractors.label !== 0 ? [res.data.enps.detractors.label] : []
            },
            {
                name: 'Neutral',
                data: res.data.enps.neutral.value !== 0 ? [res.data.enps.neutral.value] : [],
                label: res.data.enps.neutral.label !== 0 ? [res.data.enps.neutral.label] : []
            },
            {
                name: 'Promoters',
                data: res.data.enps.promoters.value !== 0 ? [res.data.enps.promoters.value] : [],
                label: res.data.enps.promoters.label !== 0 ? [res.data.enps.promoters.label] : []
            }
        ]

        const graphDataOnboarding = [
            {
                name: 'Dissatisfied',
                data: res.data.onboarding.detractors.value !== 0 ? [res.data.onboarding.detractors.value] : [],
                label: res.data.onboarding.detractors.label !== 0 ? [res.data.onboarding.detractors.label] : []
            },
            {
                name: 'Moderately Dissatisfied',
                data: res.data.onboarding.neutral.value !== 0 ? [res.data.onboarding.neutral.value] : [],
                label: res.data.onboarding.neutral.label !== 0 ? [res.data.onboarding.neutral.label] : []
            },
            {
                name: 'Very Satisfied',
                data: res.data.onboarding.promoters.value !== 0 ? [res.data.onboarding.promoters.value] : [],
                label: res.data.onboarding.promoters.label !== 0 ? [res.data.onboarding.promoters.label] : []
            }
        ]

        const seriesRadialForENPS = res.data.enps.avg;
        const seriesRadialForOnboarding = res.data.onboarding.avg;
        const companyOverviewInfo = {
            performanceForDonut: res.data.performance.avg,
            performanceForLine: res.data.performance.data.map((i: { date: string, record: number }) => ({
                x: i.date,
                y: i.record
            })),
            motivationForDonut: res.data.motivation.avg,
            motivationForLine: res.data.motivation.data.map((i: { date: string, record: number }) => ({
                x: i.date,
                y: i.record
            })),
            sentimentForDonut: res.data.sentiment.avg,
            sentimentForLine: res.data.sentiment.data.map((i: { date: string, record: number }) => ({
                x: i.date,
                y: i.record
            })),
            graphDataENPS: graphDataENPS,
            graphDataOnboarding: graphDataOnboarding,
            seriesRadialForENPS: seriesRadialForENPS,
            seriesRadialForOnboarding: seriesRadialForOnboarding,
            norms: norms
        }
        dispatch(setCompanyOverviewInfoAC(companyOverviewInfo));
        dispatch(setLoadingCommonReducerAC(false));
    }).catch(err => {
        dispatch(setLoadingCommonReducerAC(false));
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}


export const getTourCompletedFlagTC = () => async (dispatch: Dispatch<AllActionType>) => {
    dashboardAPI.getTourCompletedFlag()
        .then(res => {
            dispatch(setTourCompletedAC(res.data.tourCompleted));
        }).catch(err => dispatch(addAlertMessageCommonReducerAC({...errorAlertItem})))
}

export const getAllDepartments = () => async (dispatch: Dispatch<AllActionType>, getState: () => RootStateType) => {
    teamManagementAPI.getDepartments()
        .then(res => {
            let departments = res.data.map((dep: { id: number, name: string }) => ({
                value: dep.id.toString(),
                label: dep.name
            }))
            dispatch(setAllDepartmentsAC(departments));
        }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const getTeamsOverviewInfo = (selectedTimeFrame: { value: string, label: string },
                                     selectedDepartments: Array<{
                                         value: string,
                                         label: string
                                     }>) => async (dispatch: Dispatch<AllActionType>) => {
    let departmentsIds = selectedDepartments.map(dep => +dep.value);
    dispatch(setLoadingCommonReducerAC(true));
    dashboardAPI.teamsOverview(selectedTimeFrame.value, departmentsIds).then(res => {
        dispatch(setLoadingCommonReducerAC(false));
        dispatch(setFilteredTeamsAC(res.data.teams))
    }).catch(err => {
        dispatch(setLoadingCommonReducerAC(false));
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    });
}

export const getCompanyAnalytics = (obj: sendInfo, colorData: any, defaultColors: Array<string>, index: number) => async (dispatch: Dispatch<AllActionType>) => {
    dispatch(setLoadingCommonReducerAC(true));
    dashboardAPI.getCompanyAnalytics(obj).then(res => {

        let info = {
            motivationAvg: res.data?.company?.motivation[0],
            performanceAvg: res.data?.company?.productivity[0],
            sentimentAvg: res.data?.company?.sentiment[0],
            motivationLine: res.data?.motivation[0]?.teams?.map((i: any) => ({name: i.teamName, data: i.data})),
            performanceLine: res.data?.productivity[0]?.teams?.map((i: any) => ({name: i.teamName, data: i.data})),
            sentimentLine: res.data?.sentiments[0]?.teams?.map((i: any) => ({name: i.teamName, data: i.data})),
            motivationLineCategories: res.data?.motivation[0]?.category,
            performanceLineCategories: res.data?.productivity[0]?.category,
            sentimentLineCategories: res.data?.sentiments[0]?.category,
        }

        let arrayOfColorsForGraphs: any = [];
        let colorDataNew = colorData;
        let indexNew = index;

        info.motivationLine.map((i: any) => {
            if (!colorDataNew.has(i.name)) {
                colorDataNew.set(i.name, defaultColors[index % defaultColors.length]);
                arrayOfColorsForGraphs.push(colorDataNew.get(i.name));
                indexNew = indexNew + 1;
            } else {
                arrayOfColorsForGraphs.push(colorDataNew.get(i.name));
            }
        });

        dispatch(setAnalyticsInfoAC(info, colorDataNew, indexNew, arrayOfColorsForGraphs));
        dispatch(setLoadingCommonReducerAC(false));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
        dispatch(setLoadingCommonReducerAC(false));
    })
}

export const getAllAvaliableTeamsTC = (chosenTeamId: number | null) => async (dispatch: Dispatch<AllActionType>) => {
    dashboardAPI.getAllTeams().then(res => {
        let availableTeams = res.data.teams;
        let teamId = availableTeams?.some((i: any) => i?.id === chosenTeamId) ? chosenTeamId : availableTeams[0]?.id;
        dispatch(setChosenTeamIdAC(teamId));
        dispatch(setAvailableTeamsAC(availableTeams));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const getChosenTeamInfo = (role: string, selectedTimeFrame: { value: string, label: string },
                                  chosenTeamId: number | null, selectedTeamMember: {
        value: string,
        label: string
    } | null) => async (dispatch: Dispatch<AllActionType>) => {
    dispatch(setLoadingCommonReducerAC(true));
    dashboardAPI.getTeamInfo(chosenTeamId, selectedTimeFrame.value, selectedTeamMember?.value).then(res => {

        let norms = [];
        for (let key in res.data.norms) {
            norms.push(res.data.norms[key])
        }
        let members = res.data.team?.members.map((i: { value: number, label: string }) => ({
            value: i.value?.toString(),
            label: i.label
        }));
        members = res.data.team.members.length ? [{value: '0', label: 'All team members'}, ...members] : members;

        let chosenTeamInfo = {
            teamId: res.data.team.id,
            teamName: res.data.team.name,
            members: members,
            leader: res.data.team.leader,
            motivationAvg: res.data.motivation.avg,
            motivationLineGraph: res.data.motivation.data,
            sentimentAvg: res.data.sentiments.avg,
            sentimentLineGraph: res.data.sentiments.data,
            sentimentAvgBad: res.data.sentiments.typesAvg.bad,
            sentimentAvgGood: res.data.sentiments.typesAvg.good,
            sentimentAvgMeh: res.data.sentiments.typesAvg.meh,
            sentimentBarGraph: res.data.sentiments.typesData,
            performanceAvg: res.data.productivity.avg,
            performanceLineGraph: res.data.productivity.data,
            productivity_sentiment_category: res.data.productivity_sentiment_category,
            norms: norms
        }
        dispatch(setLoadingCommonReducerAC(false));
        dispatch(setChosenTeamInfoAC(chosenTeamInfo));
    }).catch(err => {
        dispatch(setLoadingCommonReducerAC(false));
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const setupStripeTC = (setStripe: (data: Stripe | null) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    stripeAPI.setup()
        .then(res => {
            dispatch(setInfoForModalWindowStripeAC(res.data));
            loadStripe(res.data.publishableKey).then((data) => {
                setStripe(data)
            })
        }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const checkPaymentStripeTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    stripeAPI.checkPayment()
        .then(res => {
            dispatch(setPaymentInfoForStripeAC(res.data));
        }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const createSessionStripeTC = (priceId: string, quantity: number, stripe: Stripe): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let companyId = getState().dashboard.modalWindowForStripe.companyId;
    let subscribed = getState().dashboard.modalWindowForStripe.subscribed;
    stripeAPI.createSession(priceId, companyId, quantity)
        .then((sessionData) => {
            if (!subscribed) {
                stripe.redirectToCheckout({
                    sessionId: sessionData.data.sessionId,
                });
            } else {
                stripeAPI.portal(sessionData.data.sessionId, companyId).then((data) => {
                    window.open(data.data.url, "_self");
                });
            }
        }).catch(err => dispatch(addAlertMessageCommonReducerAC({...errorAlertItem})))
}

//для демок на графиках меняем инфу
export const getDataForGraphsDemo = (graphType: DemoGraphType,
                                     teamId: number | null,
                                     memberId: string): AppThunk => async (dispatch, getState: () => RootStateType) => {

    dashboardAPI.getInfoForChangeGraphsDemos(graphType, teamId, memberId)
        .then((res) => {

            let data = (graphType === 'motivation' || graphType === 'sentiment_types') ? res.data.data
                : graphType === 'performance_sentiment' ? {...res.data} : null;
            data && dispatch(setDemoGraphDataAC(data, graphType));
        }).catch(err => dispatch(addAlertMessageCommonReducerAC({...errorAlertItem})))
}

export const saveChangedDataForGraphsDemo = (data: any, graphType: DemoGraphType, time: {
                                                 value: string,
                                                 label: string
                                             },
                                             teamId: number | null,
                                             memberId: string, closeModalWindow: (isOpen: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    dashboardAPI.saveInfoForChangeGraphsDemos(data, graphType, teamId, memberId)
        .then((res: any) => {
            dispatch(setTimeFrameAC(time));
            closeModalWindow(false);
        }).catch((err: any) => dispatch(addAlertMessageCommonReducerAC({...errorAlertItem})))
}


//для демок на графиках получае инфу enps onboarding
export const getDataForGraphsCompanyDemo = (graphType: DemoGraphType): AppThunk => async (dispatch, getState: () => RootStateType) => {

    dashboardAPI.getInfoForChangeGraphsForCompanyDemos(graphType)
        .then((res) => {
            let data = res.data.data;
            let companyId = res.data.companyId;
            dispatch(setDemoGraphDataAC(data, graphType));
            dispatch(setCompanyIdHorizontalBarGraphDataAC(companyId));
        }).catch(err => dispatch(addAlertMessageCommonReducerAC({...errorAlertItem})))
}

export const sendDataForGraphsCompanyDemo = (data: HorizontalBarPointType,
                                             graphType: 'onboarding' | 'enps' | null,
                                             time: { value: string, label: string },
                                             closeModalWindow: (isOpen: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    dashboardAPI.sendInfoForChangeGraphsForCompanyDemos(graphType, data)
        .then((res) => {
            dispatch(setTimeFrameAC(time));
            closeModalWindow(false);
        }).catch(err => dispatch(addAlertMessageCommonReducerAC({...errorAlertItem})))
}


//блок для pdf отчетов
export const getInfoForModalWindowPDFReportsTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dashboardAPI.getInfoForModalWindowPDFReports()
        .then(res => {
            dispatch(setPDFReportsModalWindowInfoAC(res.data))
        }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export type SharePdfReportsInfoType = {
    type: any,
    users: any,
    period: any,
    teams: Array<{ id: number | null, name: string | null }> | null,
    departments: Array<number>,
}

//это одна санка и на download и на send, в зависимости от того, какой тип мы передаем в нее вторым параметром ("send" или "download")
export const sharePDFReportsTC = (info: SharePdfReportsInfoType,
                                  type: 'send' | 'download',
                                  setError: () => void,
                                  setSuccess: () => void,
                                  setNotification: (text: string) => void,
                                  closeReportModalWindow?: () => void,
): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let newInfo = type === 'send' ? {...info, send: true} : {...info, download: true};
    closeReportModalWindow && closeReportModalWindow();
    type === 'send' ? dispatch(setLoaderForShareReportButtonAC(true)) : dispatch(setLoaderForDownloadReportButtonAC(true));
    dashboardAPI.sharePdfReport(newInfo)
        .then(res => {
            type === 'send' ? dispatch(setLoaderForShareReportButtonAC(false)) : dispatch(setLoaderForDownloadReportButtonAC(false));
            if (type === 'send') {
                setNotification('New report was sent');
            } else if (type === 'download') {
                const base64str = res.data.buffer;
                let a = Buffer.from(base64str, 'base64')
                let file = new Blob([a]);
                let fileURL = window.URL.createObjectURL(file);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = res.data.file;
                alink.click();
                setNotification('Successful file download');
            }
            setSuccess();
        }).catch(err => {
        type === 'send' ? dispatch(setLoaderForShareReportButtonAC(false)) : dispatch(setLoaderForDownloadReportButtonAC(false));
        setError();
        setNotification(err.response?.data?.message);
    })
}